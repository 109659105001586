<template>
  <section>
    <div class="level">
      <div class="level-left">
        <FilterNav :filters="filters" @set-filter-type="setFilterType" />
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button
            v-if="$auth.isAdmin || $auth.isOrganizer"
            type="elevation-1 has-text-weight-semibold has-text-info ml-1"
            label="Create Event Series"
            icon-left="plus-circle"
            tag="router-link"
            to="/events/create"
          />
        </div>
      </div>
    </div>

    <div class="mb-4">
      <EventList
        v-if="filterType != 'series'"
        is-call-to-action
        :is-past="filterType == 'past'"
        :max-events="100"
        :apportioned-viewable="10"
        :where="whereClause"
        :order-by="[
          {
            starts_at: filterType == 'past' ? 'desc_nulls_last' : 'asc_nulls_first'
          },
          { scheduled_starts_at: filterType == 'past' ? 'desc' : 'asc' }
        ]"
      />
      <EventSeriesListVirtual
        v-if="filterType == 'series'"
        :where="whereClauseSeries"
      />
      <center>
        <div v-if="events && events.length > 0" class="column is-half boxed pb-1 mt-6">                    
            <h4 class="mt-2">Want to attend more events?</h4>
            <p>Find more events near you or online at a time that's perfect for you!</p>
            <div
              class="button is-primary has-text-weight-bold elevation-2 my-3"
              @click="onMoreEventsCardClick"
            >
              Find an Event
            </div>          
        </div>
      </center>
    </div>
  </section>
</template>

<script>
import EventList from "@/components/events/EventList.vue";
import EventSeriesListVirtual from "@/components/eventSeries/EventSeriesListVirtual.vue";
import {
  whereCurrentUserRSVPd,
  whereCurrentUserSubscribed,
  whereCurrentUserIsOrganizer
} from "@/models/events/predicates.js";
import FilterNav from "@/components/common/FilterNav.vue";

export default {
  name: "EventsTab",
  components: {
    EventList,
    EventSeriesListVirtual,
    FilterNav
  },
  data() {
    return {
      events: [],
      eventsCount: 0,
      currentPage: 1,
      offset: 0,
      filterType: "current" //current, past, series
    };
  },
  computed: {
    whereClause() {
      let where = {
        cancelled_by_materializer_at: { _is_null: true }
      };
      if (this.filterType === "series") {
        //no filter
      }
      else if (this.filterType === "past") {
        where.ends_at = { _lte: "now()" };
      } else {
        where.ends_at = { _gte: "now()" };
      }
      where = {
        ...where,
        _or: [
          whereCurrentUserRSVPd(this.$auth.user),
          whereCurrentUserSubscribed(this.$auth.user),
          whereCurrentUserIsOrganizer(this.$auth.user)
        ]
      };
      this.$log.debug("building where clause", where);
      return where;
    },
    whereClauseSeries() {
      const now = new Date();
      const oneWeekFromNow = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
      return {
        template: {
          members: {
            user_id: { _eq: this.$auth.user.id }
          }
        },
        starts_at: {
          _gte: now.toISOString(),
          _lte: oneWeekFromNow.toISOString()
        }
      };
    },
    filters() {
      return [
        {
          type: "current",
          icon: "calendar-exclamation",
          label: "Upcoming Events"
        },
        {
          type: "past",
          icon: "calendar-times",
          label: "Past Events"
        },
        {
          type: "series",
          icon: "calendar-week",
          label: "My Subscriptions"
        },
      ];
    }
  },
  methods: {
    onPageChanged(newPage) {
      this.currentPage = newPage;
      this.offset = (newPage - 1) * this.limit;
    },
    setFilterType(f) {
      this.filterType = f;
    },
    onMoreEventsCardClick() {
      this.$router.push({
        name: "events"
      });
    }
  }
};
</script>
