<template>
  <div class="level-item is-flex sub-navigation is-align-items-center is-flex-wrap-wrap mb-2">
    <div class="tabs is-danger is-medium">
      <ul>
        <li v-for="filter in filters"
          :key="filter.type" 
          :class="{ 'is-active': activeFilter === filter.type }"
        >
          <a @click="setFilterType(filter.type)">
            <b-icon :icon="filter.icon" />
            <span class="is-hidden-mobile">{{ filter.label }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterNav",
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeFilter: null
    };
  },
  mounted() {
    this.activeFilter = this.filters.find(_ => true)?.type;
  },
  methods: {
    setFilterType(filterType) {
      this.activeFilter = filterType;
      this.$emit("set-filter-type", filterType);
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-navigation {
  @include tablet {
    justify-content: space-between;
  }

  @include mobile {
    justify-content: center;
  }
}

</style>
