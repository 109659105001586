<template>
  <div>
    <EventCardDesktop 
      v-if="suwDesktop"
      :event="event" 
      :is-active="isActive"
      :is-past="isPast"
    />
    <EventCardMobile 
      v-if="!suwDesktop"
      :event="event" 
      :is-active="isActive"
      :is-past="isPast"
    />
  </div>
</template>

<script>

import EventCardDesktop from "@/components/eventCard/EventCardDesktop.vue";
import EventCardMobile from "@/components/eventCard/EventCardMobile.vue";

export default {
  name: "EventCard",
  components: {
    EventCardDesktop,
    EventCardMobile
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    isActive: {
      // this should get turned on to highlight this event
      type: Boolean,
      default: false
    },
    isPast: {
      // we display the card differently if it has already happened
      type: Boolean,
      default: false
    }
  }
};
</script>
