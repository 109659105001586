import { slugify } from "@/filters.js";
import { toLocalDate } from "@/dates";

const eventCard = {
  props: {
    event: {
      type: Object,
      required: true
    },
    isActive: {
      // this should get turned on to highlight this event
      type: Boolean,
      default: false
    },
    isPast: {
      // we display the card differently if it has already happened
      type: Boolean,
      default: false
    }
  },
  computed: {
    venueCityState() {
      var venue_city_state = "";
      this.event.venue.api_result.address_components.forEach(ac => {
        if (ac.types.includes("locality")) {
          venue_city_state = ac.long_name;
        }
        if (ac.types.includes("administrative_area_level_1")) {
          venue_city_state += ", " + ac.short_name;
        }
      });
      return venue_city_state;
    },
    hostCity() {
      return this.event.venue.host_city.replace(", USA", "");
    },
    hostUser() {
      let organizers = this.event.template ? this.event.template.organizers : this.event.organizers;
      if (organizers.length < 1) {
        this.$log.warn(
          "series has no organizers. event_template_id:",
          this.event.template.id
        );
        return null;
      }
      const u = organizers[0].user;
      return u;
    }
  },
  methods: {
    toLocalDate,
    onEventCardClick() {
      this.$router.push({
        name: "event_detail",
        params: {
          event_id: this.event.id,
          slug: slugify(this.event)
        }
      });
    },
  }
}

export default eventCard;