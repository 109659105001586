<template>
  <div>
    <slot v-if="fakeLoading || $apollo.queries.events.loading" name="Loading">
      <div v-for="i in maxEvents" :key="i" class="is-flex line-item">
        <div style="flex-grow: 0;" class="my-2 ml-2">
          <b-skeleton width="120px" height="120px" />
        </div>
        <div style="width: 100%;" class="pl-2 pt-2">
          <b-skeleton size="is-large" :width="rand(40, 70)" />
          <b-skeleton :width="rand(60, 280)" />
        </div>
      </div>
    </slot>

    <slot v-else-if="totalEvents > 0">
      <EventCard v-for="e in orderedEvents" :key="e.id" :event="e" :is-past="isPast" class="p-0" />
      <p
        v-if="apportionedViewable > 0 && currentlyShowing < events.length"
        class="is-flex is-justify-content-space-around m-3"
      >
        <a
          class="has-text-grey-dark has-text-weight-bold is-size-5"
          @click="currentlyShowing += apportionedViewable"
        >
          Load More
        </a>
      </p>
    </slot>

    <slot v-else name="empty">
      <EmptyEvents v-if="isCallToAction" />
      <EmptyState v-else>
        No events found.
      </EmptyState>
    </slot>
  </div>
</template>

<script>
import { EVENTS_LIST } from "@/models/events/operations.gql";
import EventCard from "@/components/eventCard/EventCard.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import EmptyEvents from "@/components/dashboard/EmptyEvents.vue";

export default {
  name: "EventList",
  components: {
    EmptyState,
    EventCard,
    EmptyEvents
  },
  props: {
    where: {
      type: Object,
      default: null
    },
    orderBy: {
      type: Array,
      default: () => [{ starts_at: "asc_nulls_first" }, { scheduled_starts_at: "asc" }]
    },
    maxEvents: {
      type: Number,
      default: 10
    },
    //Fetch all of the events (up to maxEvents), but only render this many at a time
    apportionedViewable: {
      type: Number,
      default: 0
    },
    isCallToAction: {
      // for when we want to show "Find" instead of "None"
      type: Boolean,
      default: false
    },
    isPast: {
      // we display the cards differently if it has already happened
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      events: [],
      totalEvents: 0,
      fakeLoading: false,
      currentlyShowing: this.apportionedViewable
    };
  },
  computed: {
    orderedEvents() {
      var eventsForSort = [...this.events];
      var sortedEvents = eventsForSort.sort((a, b) => {
        var a_start = a.starts_at || a.scheduled_starts_at;
        var b_start = b.starts_at || b.scheduled_starts_at;
        return a_start < b_start ? (this.isPast ? 1 : -1) : this.isPast ? -1 : 1;
      });
      return this.apportionedViewable > 0 ? sortedEvents.slice(0, this.currentlyShowing) : sortedEvents;
    }
  },
  apollo: {
    events: {
      query: EVENTS_LIST,
      variables() {
        return {
          offset: 0,
          limit: this.maxEvents,
          where: this.where,
          orderBy: this.orderBy,
          expand_organizers: true
        };
      },
      update({
        events,
        events_aggregate: {
          aggregate: { count }
        }
      }) {
        this.totalEvents = count;
        this.$log.debug(events);
        return events;
      }
    }
  },
  methods: {
    rand(minimum, maximum) {
      const val = minimum + Math.random() * (minimum - maximum);
      return `${val}%`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
