<template>
  <div class="event-listing elevation-1 boxed is-hover-grow is-align-items-center"
    :class="{
      'is-active': isActive
    }"
    @click="onEventCardClick"
  >
    <DateSquare
      :d="toLocalDate(event.starts_at || event.scheduled_starts_at)"
      :is-featured="event.template.featured_at !== null"
      :is-cancelled="event.cancelled_by_user_at !== null || event.cancelled_by_materializer_at !== null"
      class="mr-2"
    />
    <div class="event-info"
      :class="{
        cancelled: event.template.cancelled_by_user_at !== null
      }"
    >

    <div class="is-flex-grow-1">
      <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
        <div class="is-flex is-align-items-center">
          <h5>
            <div 
              class="mb-1 has-text-weight-medium" 
              :class="{'has-text-grey-light': isPast}"
            >
              {{ event | format_title }}
            </div>
          </h5>
          <b-tag
            v-if="event.starts_at || event.venue_id"
            class="is-pink ml-1 mb-1"
          >
            Updated
          </b-tag>
          <b-tag
            v-if="event.cancelled_by_user_at || event.cancelled_by_materializer_at"
            class="is-danger ml-1 mb-1"
          >
            CANCELLED
          </b-tag>
          <b-tag
            v-if="!isPast && event.template.original_venue_was_virtual && !event.current_user_is_rsvpd"
            class="is-warning is-light ml-1 mb-1"
          >
            NOT RSVPed
          </b-tag>
        </div>
      </div>
      <div class="mb-1">
        <div>
          <div class="is-align-self-center has-text-grey600">
            Hosted by {{ hostUser | user_name }}
          </div>
        </div>        
      </div>
      <div
        class="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-space-between"
        :class="{'has-text-grey-light': isPast}"
      >
        {{ hostCity }}
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import eventCard from "@/components/eventCard/EventCard.js";
import DateSquare from "@/components/dates/DateSquare.vue";

export default {
  name: "EventCardMobile",
  mixins: [eventCard],
  components: {
    DateSquare
  }
};
</script>

<style lang="scss" scoped>
.date {
  width: 120px;
  height: 120px;
  border-radius: 2px;
  background: $background;
  border: 1px solid $border;
  flex-grow: 0;
  flex-shrink: 0;
}
.card {
  &.is-active {
    background: $warning-light;
  }
}
.tz {
  padding: 2px;
  font-size: 11px;
  background-color: white;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
